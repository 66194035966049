import { startNProgress, stopNProgress } from 'src/utils/nprogress'
import { getAuthHeaders, getLMApiKey } from '..'
import { NextPageContext } from 'next'
import { ServerURL } from 'src/config'
import axios from 'axios'
import { isValidUUID } from 'src/utils/uuid/index'
import { IParcelCertified, IParcelName } from 'src/interfaces/osp'

export async function getOrgsOSP(ctx: NextPageContext, orgId: string, page: number = 1) {
  const config = await getAuthHeaders(ctx)
  return await axios.get(`${ServerURL}/organizations/${orgId}/osps?page=${page}`, config)
}

export async function getAnOsp(ctx: NextPageContext, ospId: string) {
  if (!isValidUUID(ospId as string)) {
    throw new Error('Invalid UUID')
  }
  const config = await getAuthHeaders(ctx)
  return await axios.get(`${ServerURL}/osps/${ospId}/progress`, config)
}

export async function getQuestionAnswers(ctx: NextPageContext) {
  const config = await getAuthHeaders(ctx)
  return await axios.get(`${ServerURL}/questions`, config)
}

export async function getQuestion(ctx: NextPageContext, ospId: string, moduleId: string, identifiers: string) {
  const config = await getAuthHeaders(ctx)
  return await axios.get(`${ServerURL}/osps/${ospId}/modules/${moduleId}/answers?question_identifiers=${identifiers}`, config)
}
export async function submitOsp(ospId: string, ctx?: NextPageContext) {
  const config = await getAuthHeaders(ctx)
  return await axios.post(`${ServerURL}/osps/${ospId}/submit`, null, config)
}

export async function createParcel(osp_id: string, module_identifier: string, parcelData: any, ctx?: NextPageContext) {
  const config = await getAuthHeaders(ctx)

  return await axios.post(`${ServerURL}/parcels`, { osp_id, module_identifier, ...parcelData }, config)
}
export async function getParcelData(parcelId: string, ctx?: NextPageContext) {
  const config = await getAuthHeaders(ctx)
  return await axios.get(`${ServerURL}/parcels/${parcelId}`, config)
}

export async function getParcels(osp_id: string, ctx?: NextPageContext) {
  const config = await getAuthHeaders(ctx)
  return await axios.get(`${ServerURL}/osps/${osp_id}/parcels`, config)
}

export async function editParcel(parcelId: string, data: IParcelName) {
  const config = await getAuthHeaders()
  return await axios.patch(`${ServerURL}/parcels/${parcelId}`, data, config)
}

export async function editParcelCertified(parcelId: string, data: IParcelCertified) {
  const config = await getAuthHeaders()
  return await axios.patch(`${ServerURL}/parcels/${parcelId}`, data, config)
}

export async function deleteParcel(parcelId: string) {
  const config = await getAuthHeaders()
  return await axios.delete(`${ServerURL}/parcels/${parcelId}`, config)
}

export async function getLandManagers(parcelId: string) {
  const config = await getAuthHeaders()
  return await axios.get(`${ServerURL}/parcels/${parcelId}/pof`, config)
}

export async function getLandManagerStatus(key: string) {
  const config = getLMApiKey(key)
  return await axios.get(`${ServerURL}/pof/get-progress`, config)
}
// Working
//get document file /v1/organizations/documents/download/128b6ecb-3e1d-4a9a-9cf6-f52dee34d8b8.pdf

//not working
//get document file /v1/organizations/documents/download/31b43945-a807-4c33-9175-23fdeb55fc54.

export async function getDocumentFile(link: string) {
  try {
    startNProgress()
    const config = await getAuthHeaders()
    const index = ServerURL?.lastIndexOf('/')
    const host = ServerURL?.slice(0, index)
    const res = await fetch(host + link, { ...config })
    const blob = await res.blob()
    // change the url to conatin the file name
    open(URL.createObjectURL(blob))
  } catch (error: any) {
    console.log(error.message)
  } finally {
    stopNProgress()
  }
}

export async function setNotApplicable(module: string, ospId: string) {
  // const config = getLMApiKey(key)
  const config = await getAuthHeaders()
  module = module.toLowerCase().split(' ').join('-')
  // console.log('Not applicable URL', `${ServerURL}/osps/${ospId}/modules/${module}/deactivate`, config)

  return await axios
    .post(`${ServerURL}/osps/${ospId}/modules/${module}/deactivate`, null, config)
    .then((res) => console.log(res))
    .catch((err) => console.log(err))
}
