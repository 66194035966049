import { ICoords } from 'src/interfaces/osp'
import { NextRouter } from 'next/router'
import toast from 'react-hot-toast'

export function getDoubleLettersName(name: string) {
  name = name.trim()
  if (!name) return ''
  const arr = name.split(' ').filter((i) => /^[a-z]/i.test(i[0]) && i)
  let letters = arr[0][0]
  if (arr.length > 1) letters += arr[arr.length - 1][0]
  return letters.toUpperCase()
}

export function getPreviousPage(currentPath: string) {
  const basePath = currentPath.slice(0, currentPath.lastIndexOf('/'))
  return basePath
}

export function relativeToNextPage({ asPath, push, replace }: NextRouter, page: string, rep: boolean = false) {
  const basePath = asPath.slice(0, asPath.lastIndexOf('/'))
  const p = `${basePath}/${page}`
  if (!rep) return push(p)
  replace(p)
}

export function getBase64File(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
}

export function validateFiles(file: any): boolean {
  //'.pdf,.csv,.doc,.docx,.xlsx,.txt,.jpg,.jpeg,.png,.heic'
  const extRegex = /(.jpg|.jpeg|.gif|.png|.heic|.doc|.pdf|.csv|.docx|.xlsx|.txt)$/

  switch (true) {
    case !extRegex.test(file?.name?.toLowerCase() as string):
      toast.error('This is not a supported file type. Please attach one of the following supported file types: pdf, doc, docx, xlsx, csv, png, jpeg, heic, or tiff.', { duration: 4000 })
      return false
    case file.size > 10000000:
      console.log(file.size)
      toast.error('This file exceeds the maximum upload limit of 10MB. Please select a smaller file.', { duration: 4000 })
      return false
    default:
      return true
  }
}

export function getFormattedDate(date: string) {
  const [yyyy, dd, mm] = date?.split(/[-/]/)
  return `${yyyy}-${mm}-${dd}`
}

export function formatDate(date: string) {
  const [yyyy, mm, dd] = date?.split(/[-/]/)
  return `${mm}/${dd}/${yyyy}`
}

export function getUTCToString(date: string) {
  const d = new Date(date)
  return `${d.toLocaleDateString()} - ${d.toLocaleTimeString()}`
}

export function getPolygonCentroid(points: ICoords[]) {
  //Correction for very small polygons:

  if (!points || points?.length === 0) return
  const x0 = points[0].latitude,
    y0 = points[0].longitude

  let x = 0,
    y = 0,
    twiceArea = 0

  let prev = points[points.length - 1]
  for (const next of points) {
    const x1 = prev.latitude - x0,
      y1 = prev.longitude - y0,
      x2 = next.latitude - x0,
      y2 = next.longitude - y0,
      a = x1 * y2 - x2 * y1

    twiceArea += a
    x += (x1 + x2) * a
    y += (y1 + y2) * a

    prev = next
  }

  const factor = 3 * twiceArea // 6 * twiceArea/2
  x /= factor
  y /= factor

  return { lat: x + +x0, lng: y + +y0 }
}

export function getNestedPath(orgId: string, page: 'osps' | 'dms' | 'activity-tracker') {
  return `/organizations${orgId ? '/'.concat(orgId, '/') : '?page='}${page}`
}
